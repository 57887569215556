@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  /* font-family: 'Comfortaa', sans-serif !important;  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.altezza-ridotta {
  height: 0 !important;
  /* opacity: 0 !important;  */
}

.MuiCircularProgress-root {
  border-radius: 1000px !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px !important;
}


@media (max-width: 769px) {
  .mapboxgl-ctrl-top-right {
    top: 100px;
  }
}